.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #111921;
  height: 100vh;
  width: 100vw;
}

.dashboardColumn {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  overflow-y: scroll;
  color: white;
}

.columnList {
  display: flex;
  flex-wrap: nowrap;
  margin: 20px;
}

.cardName {
  font-weight: bold;
  font-size: large;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  color: white;
}

.cardList {
  margin-top: 10px;
}

.header {
  flex-direction: row;
  align-items: center;
}

.card {
  background-color: #1f3652;
  border: 1px solid #ddd;
  background-image: linear-gradient(to top, #12202e, #12202e);
  background-size: 100% 50px;
  background-repeat: no-repeat;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.dutycard {
  background-color: #1f3652;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.profilePhoto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.hide {
  display: none;
}

.show {
  display: flex;
}

.matching-card {
  border: 1px solid #ccc;
  background-color: #1f3652;
  padding: 10px;
  margin: 5px 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80px;
  border-radius: 10px;
  background-image: linear-gradient(to top, #12202e, #12202e);
  background-size: 100% 40px;
  background-repeat: no-repeat;
}

.Icon {
  color: rgb(49, 49, 216);
}

.NameText {
  color: white;
}

.NameTextmemo {
  color: white;
  margin-left: 12px;
  align-self: center;
}

.unitCard {
  background-color: #1f3652;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unitName {
  font-weight: bold;
  color: white;
}

.unitStatus {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}

.outOfService {
  background-color: red;
  color: white;
}

.inService {
  background-color: green;
  color: white;
}
